
h1, h2 {
  font-weight: normal;
}

h1 {
  margin-top: 5rem;
}

h2 {
  margin: 0 0 0.8rem;
  font-size: 1.3em;
}

a, a:hover, a:active, a:visited {
  color: white;
  text-decoration: none;
}
